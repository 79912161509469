






import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

@Component
export default class CompanyPage extends Vue {
  @Getter("company/isActive") companyActive!: boolean;
  @Getter("company/isOwner") isOwner!: boolean;
  @Getter("company/isEmployee") isEmployee!: boolean;
  @Getter("profile/getUserEmail") myEmail!: string;

  @Watch("$route", { immediate: true })
  routeChanged() {
    const { path } = this.$route;
    //not owner of company; can't go to anything else but "/"
    //if (path.startsWith("/company") && this.companyActive && !this.isOwner)
    if (path.startsWith("/company") && this.companyActive && !this.isEmployee)
      if (path !== "/company") this.$router.replace("/company");
  }
}
